// TV2DK HEADER DIALOG
@import "core";

// HEADER
@include respond-to(base-inline) {
  .tc_header__dialog{position:relative;background-color:var(--color-100);width:calc(384 * var(--unit));max-width:100%;margin-left:auto;height:100%;min-height:100%;font-size:var(--font-14);}
  .tc_header__dialog__header{position:sticky;top:0;left:0;right:0;z-index:2;display:flex;flex-direction:row-reverse;align-items:center;justify-content:flex-start;gap:var(--spacing-12);height:var(--spacing-72);padding:0 var(--gutter);background-color:var(--color-100);}
  .tc_header__dialog__header__close .tc_button__text{display:none;}
  .tc_header__dialog__header__login{
    svg:last-child{display:none;}
    &[data-logged-in="true"]{
      svg:first-child{display:none;}
      svg:last-child{display:block;}
    }
  }
  .tc_header__dialog__header__logo{display:block;width:var(--spacing-40);height:var(--spacing-40);margin-right:auto;border-radius:50%;
    svg{width:100%;height:100%;}
  }
  .tc_header__dialog__main{display:flex;flex-direction:column;gap:var(--spacing-8);padding:var(--spacing-16) var(--spacing-8);overflow:auto;overscroll-behavior:contain;max-height:calc(100% - var(--spacing-104));}
  .tc_header__dialog__main__header{font-size:var(--font-12);color:var(--color-500);font-weight:var(--font-medium);padding:var(--spacing-20) var(--spacing-16) var(--spacing-4);}
  .tc_header__dialog__main__nav{display:flex;flex-direction:column;gap:var(--spacing-4);list-style:none;}
  .tc_header__dialog__main__nav__item{background-color:var(--color-light);
    &:is(details){transition:max-height var(--transition-slower);overflow:hidden;
      & > summary{position:relative;background:var(--color-light);padding-right:var(--spacing-48-step);cursor:pointer;transition:background-color var(--transition-fast);
        &:focus-visible{outline-offset:calc(var(--spacing-2) * -1);}
        &:active .tc_header__dialog__main__nav__item__link::before{scale:var(--button-active-scale);}
        &[tabindex="-1"]{pointer-events:none;
          .tc_header__dialog__main__nav__item__link{pointer-events:auto;
            &::before,&::after{display:none;}
          }
        }
        .tc_header__dialog__main__nav__item__link{display:inline-flex;
          &::before,&::after{content:"";position:absolute;right:var(--spacing-8);top:0;bottom:0;margin:auto 0;width:var(--spacing-40);height:var(--spacing-40);pointer-events:none;}
          &::before{border-radius:50%;transition:background-color var(--transition-normal);}
          &::after{mask:url("inline!../../../../icons/chevron-down.svg") no-repeat 50% 50%;mask-size:var(--spacing-16) auto;background-color:var(--color-dark);}
        }
      }
      &[open]{
        .tc_header__dialog__main__nav__item__link{
          &::before{background-color:var(--color-200);}
          &::after{transform:rotate(180deg);}
        }
      }
    }
  }
  .tc_header__dialog__main__nav__item__link{flex:1;display:flex;align-items:center;padding:var(--spacing-16);min-height:var(--spacing-24);color:var(--color-text);font-weight:var(--font-medium);text-decoration:none;transition:color var(--transition-fast);
    &:focus-visible{outline-offset:calc(var(--spacing-2) * -1);}
    &.tc_button{font-size:inherit;justify-content:flex-start;padding:var(--spacing-16);color:var(--color-text);font-weight:var(--font-medium);text-decoration:none;}
    &[aria-current="true"]{color:var(--color-primary-tv2red);}
  }
  .tc_header__dialog__main__nav__item__subnav{list-style:none;position:relative;z-index:1;}
  .tc_header__dialog__main__nav__item__subnav__subitem__link{display:flex;align-items:center;min-height:var(--spacing-20);padding:var(--spacing-12) var(--spacing-32);color:var(--color-text);line-height:var(--line-130);font-weight:var(--font-medium);text-decoration:none;transition:color var(--transition-fast);
    &:focus-visible{outline-offset:calc(var(--spacing-2) * -1);}
    &[aria-current="true"]{color:var(--color-primary-tv2red);}
  }

  .tc_header__dialog__main__theme{padding:var(--spacing-8) var(--spacing-16) var(--spacing-16);}

  // Light theme fallback (iOS < 17.5)
  @supports not (color: light-dark(#fff, #000)) {
    .tc_header__dialog__main__theme{
      &::before{content:"Funktionen kræver en nyere browser";color:var(--color-500);}
      .tc_form-fieldset__body{display:none;}
    }
  }

  .tc_header[data-site="sport"]{
    .tc_header__dialog__main__nav__item__link,.tc_header__dialog__main__nav__item__subnav__subitem__link{
      &[aria-current="true"]{color:var(--color-primary-blue);}
    }
  }
  .tc_header[data-site="vejr"]{
    .tc_header__dialog__main__nav__item__link,.tc_header__dialog__main__nav__item__subnav__subitem__link{
      &[aria-current="true"]{color:var(--color-primary-green);}
    }
  }
  .tc_header[data-site="echo"]{
    .tc_header__dialog__main__nav__item__link,.tc_header__dialog__main__nav__item__subnav__subitem__link{
      &[aria-current="true"]{color:var(--color-primary-violet);}
    }
  }

  @supports (height:1dvh){
    .tc_header__dialog{height:auto;min-height:100dvh;}
    .tc_header__dialog__main{max-height:calc(100dvh - var(--spacing-96));}
  }

  // no JS fallback
  .tc_header__global__buttons__dialog:popover-open{display:block;transform:translateX(0);
    [data-cmp="cpc-open"]{color:var(--color-500);pointer-events:none;
      &::after{content:"(kræver JavaScript)";}
    }
    .tc_header__dialog__main__theme{
      &::after{content:"Funktionen kræver Javascript";color:var(--color-500);}
      .tc_form-fieldset__body{display:none;}
    }
  }
}

@include respond-to(base) {
  [class*="tc_header__dialog__main__nav__item__link--icon"]{position:relative;padding-left:var(--spacing-48);
    &::before{content:"";position:absolute;left:0;top:0;bottom:0;width:var(--spacing-48);mask-repeat:no-repeat;mask-position:var(--spacing-16) 50%;mask-size:var(--spacing-16) auto;background-color:var(--color-dark);}
  }
  .tc_header__dialog__main__nav__item__link--icon-sport::before{mask-image:url("../../../../icons/trophy.svg");}
  .tc_header__dialog__main__nav__item__link--icon-weather::before{mask-image:url("../../../../icons/weather.svg");}
  .tc_header__dialog__main__nav__item__link--icon-tvguide::before{mask-image:url("../../../../icons/tv.svg");}
  .tc_header__dialog__main__nav__item__link--icon-recipes::before{mask-image:url("../../../../icons/recipe.svg");}
}

@include respond-to(medium-inline) {
  .tc_header__dialog__header{width:var(--spacing-40);height:0;padding:0;background-color:transparent;margin-left:calc(var(--spacing-56) * -1);}
  .tc_header__dialog__header__close{top:calc(var(--spacing-20) + var(--spacing-16));}
  .tc_header__dialog__header__login,.tc_header__dialog__header__play,.tc_header__dialog__header__logo{display:none;}
  .tc_header__dialog__main{max-height:calc(100% - var(--spacing-32));}

  @supports (height:1dvh){
    .tc_header__dialog__main{max-height:calc(100dvh - var(--spacing-32));}
  }

  @supports not selector(::backdrop) {
    .tc_header__dialog__main{box-shadow:0 0 var(--spacing-8) var(--color-900);}
  }

  @supports selector(::backdrop) {
    .tc_header__dialog__header{
      .tc_header__dialog__header__close{background-color:var(--color-300);
        &:hover{background-color:var(--color-200);} // special hover because of button color change in breakpoint
      }
    }
  }
}

@include respond-to(hover) {
  .tc_header__dialog__main__nav__item__link,.tc_header__dialog__main__nav__item__subnav__subitem__link{
    &:hover{text-decoration:underline;}
    &.tc_button:hover{text-decoration:underline;}
  }
  .tc_header__dialog__main__nav__item{
    &:is(details){
      summary{
        &:hover .tc_header__dialog__main__nav__item__link::before{background-color:var(--color-200);}
        .tc_header__dialog__main__nav__item__link:hover::before{background-color:transparent;}
      }
    }
  }
}
